export interface NavigationInfo {
    key: string;
    name: string;
    icon: string;
    roles: Array<string>
}

export const navigations: NavigationInfo[] = [
    {
        key: "dashboard",
        name: "Dashboard",
        icon: "home.svg",
        roles: ["ALL"]
    },
    {
        key: "team",
        name: "Team",
        icon: "users.svg",
        roles: ["ROLE_ADMIN","ROLE_OPS"]
    },
    {
        key: "organization",
        name: "Organization",
        icon: "truck.svg",
        roles: ["ROLE_OPS"]
    }
];
