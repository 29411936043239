import { GetVersionResponse } from "@/interfaces/GetVersionResponse";

console.log("[http] API URL is: ", process.env.VUE_APP_API_URL);

let apiAvailableCache: boolean;

export async function apiAvailable(): Promise<boolean> {
    if (typeof apiAvailableCache === "boolean") return apiAvailableCache;
    if (!process.env.VUE_APP_API_URL) {
        apiAvailableCache = false;
        return false;
    }

    try {
        const rawResponse = await fetch(process.env.VUE_APP_API_URL + "/management/info", {
            method: "GET"
        });
        const response = await rawResponse.json();
        console.log("[http] Got API reponse with version: ", response.build.version);
        apiAvailableCache = true;
        return true;
    } catch (e) {
        console.error("[http] Couldn't reach API instance!");
        apiAvailableCache = false;
        return false;
    }
}

export async function getRequest<ResponseType>(
    url: string,
    token: string
): Promise<ResponseType> {
    if (!url.startsWith("/")) url = "/" + url;
    const rawResponse = await fetch(process.env.VUE_APP_API_URL + "/api" + url, {
        method: "GET",
        headers: {
            Authorization: token
        }
    });
    const response = await rawResponse.json();
    if (!rawResponse.ok) {
        throw new Error(response.error);
    }
    return response;
}

export async function postRequest<RequestType, ResponseType>(
    url: string,
    data: RequestType,
    token: string
): Promise<ResponseType> {
    if (!url.startsWith("/")) url = "/" + url;
    const rawResponse = await fetch(process.env.VUE_APP_API_URL + "/api" + url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(data)
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return rawResponse;
}

export async function putRequest<RequestType, ResponseType>(
    url: string,
    data: RequestType,
    token: string
): Promise<ResponseType> {
    if (!url.startsWith("/")) url = "/" + url;
    const rawResponse = await fetch(process.env.VUE_APP_API_URL + "/api" + url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(data)
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return rawResponse;
}
