
import { Options, Vue } from "vue-class-component";
import "./styles/app.scss";
import { useStore } from "@/store";
import { apiAvailable } from "./util/http";

@Options({})
export default class App extends Vue {
    // async created() {
    //     apiAvailable(); // once called the value is cached, that triggers the cache
    //     await this.$store.dispatch("GET_ACCOUNT");
    // }
}
