import {
    createRouter,
    createWebHistory,
    NavigationGuardNext,
    RouteRecordRaw,
    RouteLocationNormalized
} from "vue-router";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import {authenticator} from "@/util/authenticator";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "",
        component: DefaultLayout,
        children: [
            {
                name: "HomeView.vue",
                path: "",
                component: () =>
                    import(
                        /* webpackChunkName: "home-view" */ "../views/HomeView.vue"
                    )
            }
        ]
    },
    {
        path: "/account",
        name: "",
        component: DefaultLayout,
        children: [
            {
                name: "AccountView.vue",
                path: "",
                component: () =>
                    import(
                        /* webpackChunkName: "home-view" */ "../views/AccountView.vue"
                    )
            }
        ]
    },
    {
        path: "/team",
        name: "",
        component: DefaultLayout,
        children: [
            {
                name: "TeamView.vue",
                path: "",
                component: () =>
                    import(
                        /* webpackChunkName: "home-view" */ "../views/TeamView.vue"
                    )
            }
        ]
    },
    {
        path: "/organization",
        name: "",
        component: DefaultLayout,
        children: [
            {
                name: "OrganizationView.vue",
                path: "",
                component: () =>
                    import(
                        /* webpackChunkName: "home-view" */ "../views/OrganizationView.vue"
                    )
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next:NavigationGuardNext) => {
    authenticator.isAuthenticated().then((authenticated: boolean) => {
        if (authenticated) {
            next();
        } else {
            authenticator.signIn();
        }
    }).catch(() => {
        authenticator.signIn();
    });
});

export default router;
