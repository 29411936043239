
import { Options, Vue } from "vue-class-component";
import { NavigationOption } from "@/interfaces/NavigationOption";
import router from "@/router";

@Options({
    props: {
        options: {
            type: Array,
            required: true
        },
        selectedKey: {
            type: String,
            required: true
        }
    },
    watch: {
        // If the selected key gets changed from the parent component,
        // we listen and update the selected item internally too
        selectedKey() {
            this.selected =
                this.options.find(
                    ({ key }: NavigationOption) => key === this.selectedKey
                ) ?? this.options[0];
        }
    }
})
export default class Navigation extends Vue {
    options!: Array<NavigationOption>;
    selectedKey!: string;
    selected?: NavigationOption = {
        key: "",
        displayValue: "",
        icon: "",
        roles: []
    };

    created() {
        this.selected =
            this.options.find(({ key }) => key === this.selectedKey) ??
            this.options[0];
        this.$emit("update:selectedKey", this.selected?.key);
    }

    setOption(option: NavigationOption) {
        this.selected = option;
        this.$emit("update:selectedKey", this.selected?.key);
        if (option.key === "dashboard") {
            router.push("/");
        } else {
            router.push(`/${option.key}`);
        }
    }
}
