import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d1b74dd0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav d-flex mb-sm-auto mx-3" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "d-flex flex-column justify-content-center nav-icon" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "d-flex flex-column ms-1 nav-display-value" }
const _hoisted_6 = { class: "tooltip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
      return (_openBlock(), _createElementBlock("a", {
        class: "nav-item w-100 text-decoration-none rounded",
        key: i,
        onClick: ($event: any) => (_ctx.setOption(option))
      }, [
        _createElementVNode("li", {
          class: _normalizeClass(["d-flex flex-row nav-link align-middle py-0", 
                    _ctx.$route.path.slice(1) === option.key ||
                    (option.key === 'dashboard' && _ctx.$route.path === '/')
                        ? `rounded bg-primary text-white`
                        : ``
                ])
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              alt: "...",
              src: require(`../../../assets/icons/${option.icon}`),
              class: _normalizeClass(["me-3", 
                            _ctx.$route.path.slice(1) === option.key ||
                            (option.key === 'dashboard' && _ctx.$route.path === '/')
                                ? `icon-active`
                                : ``
                        ]),
              width: 18,
              height: 18
            }, null, 10, _hoisted_4)
          ]),
          _createElementVNode("p", _hoisted_5, _toDisplayString(option.displayValue), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(option.displayValue), 1)
        ], 2)
      ], 8, _hoisted_2))
    }), 128))
  ]))
}