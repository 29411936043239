
import { Options, Vue } from "vue-class-component";
import {authenticator} from "@/util/authenticator";

@Options({})
export default class Navbar extends Vue {
    private showDropdown = false;

    get account() {
        return this.$store.getters.account;
    }

    created() {
        window.addEventListener("click", this.closeDropdown);
    }
    beforeDestroy() {
        window.removeEventListener("click", this.closeDropdown);
    }

    signOut() {
        authenticator.signOut();
    }

    triggerDropdownMenu() {
        this.showDropdown = !this.showDropdown;
    }

    closeDropdown(e: Event) {
        if (!this.$el.contains(e.target)) {
            this.showDropdown = false;
        }
    }
}
