import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, key } from "./store";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

createApp(App)
    .use(store, key)
    .use(Toast, {
        // Can be either a string or an array of strings
        containerClassName: "toast-container",
        timeout: 10000
    })
    .use(router)
    .mount("#app");
