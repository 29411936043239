import {
    createStore,
    useStore as baseUseStore,
    Store,
    ActionContext
} from "vuex";
import { InjectionKey } from "vue";
import { authenticator } from "@/util/authenticator";
import { getRequest, postRequest, putRequest } from "@/util/http";
import { GetAccountResponse } from "@/interfaces/GetAccountResponse";
import { GetOrganizationResponse } from "@/interfaces/GetOrganizationResponse";
import { GetTeamResponse } from "@/interfaces/GetTeamResponse";
import { AddOrganizationRequest } from "@/interfaces/AddOrganizationRequest";
import { EditOrganizationRequest } from "@/interfaces/EditOrganizationRequest";

export interface StoreI {
    isAuthenticated: boolean;
    account: GetAccountResponse;
    team: GetTeamResponse[];
}

export const key: InjectionKey<Store<StoreI>> = Symbol();

export const useStore = () => {
    return baseUseStore(key);
};

export const store = createStore({
    state: {
        isAuthenticated: false,
        account: {
            id: "",
            login: "",
            firstName: "",
            lastName: "",
            email: "",
            imageUrl: null,
            activated: false,
            langKey: "",
            createdAt: "",
            authorities: []
        },
        team: []
    },
    getters: {
        account(state: StoreI) {
            return state.account;
        },
        team(state: StoreI) {
            return state.team;
        }
    },
    mutations: {
        storeUserInfo(state: StoreI, accountInfo: GetAccountResponse) {
            state.account = accountInfo;
        }
    },
    actions: {
        async GET_ACCOUNT(context: ActionContext<StoreI, StoreI>) {
            const token = await authenticator.getAccessToken();
            try {
                const account = await getRequest<GetAccountResponse>(
                    "/account",
                    `Bearer ${token}`
                );
                console.log("[store/index] Got account response: ", account);
                context.commit("storeUserInfo", account);
                return account;
            } catch (e) {
                console.error("[store/index] Something went wrong");
            }
        },
        async UPDATE_ACCOUNT(context: ActionContext<StoreI, StoreI>, data) {
            const token = await authenticator.getAccessToken();
            const response = await postRequest<any, any>(
                "/account",
                data,
                `Bearer ${token}`
            );
            console.log(
                "[store/index] Got update account response: ",
                response
            );
            if (response.status === 200 || response.status === 201) {
                return response;
            } else {
                throw new Error("something went wrong");
            }
        },
        async UPDATE_PASSWORD(context: ActionContext<StoreI, StoreI>, data) {
            const token = await authenticator.getAccessToken();
            const response = await postRequest<any, any>(
                "/account/password/change",
                data,
                `Bearer ${token}`
            );
            console.log(
                "[store/index] Got update account response: ",
                response
            );
            if (response.status === 200 || response.status === 201) {
                return response;
            } else {
                throw new Error("something went wrong");
            }
        },

        async GET_ORGANIZATIONS() {
            const token = await authenticator.getAccessToken();
            try {
                const organizations = await getRequest<GetOrganizationResponse>(
                    "/organizations",
                    `Bearer ${token}`
                );
                console.log(
                    "[store/index] Got organizations response: ",
                    organizations
                );
                return organizations;
            } catch (e) {
                console.error("[store/index] Something went wrong");
            }
        },
        async ADD_ORGANIZATION(context: ActionContext<StoreI, StoreI>, data) {
            const token = await authenticator.getAccessToken();

            const response = await postRequest<AddOrganizationRequest, any>(
                "/organizations",
                data,
                `Bearer ${token}`
            );
            console.log(
                "[store/index] Got create organizations response: ",
                response
            );
            if (response.status === 200 || response.status === 201) {
                return response;
            } else {
                throw new Error("something went wrong");
            }
        },
        async EDIT_ORGANIZATION(context: ActionContext<StoreI, StoreI>, data) {
            const token = await authenticator.getAccessToken();

            const response = await putRequest<EditOrganizationRequest, any>(
                "/organizations",
                data,
                `Bearer ${token}`
            );
            console.log(
                "[store/index] Got edit organization response: ",
                response
            );
            if (response.status === 200 || response.status === 201) {
                return response;
            } else {
                throw new Error("something went wrong");
            }
        },

        async GET_TEAM_MEMBERS(context: ActionContext<StoreI, StoreI>) {
            const token = await authenticator.getAccessToken();
            try {
                const members = await getRequest<any>(
                    "/team/members",
                    `Bearer ${token}`
                );
                console.log("[store/index] Got members response: ", members);
                return members;
            } catch (e) {
                console.error("[store/index] Something went wrong");
            }
        },
        async ADD_TEAMMEMBER(context: ActionContext<StoreI, StoreI>, data) {
            const token = await authenticator.getAccessToken();

            const response = await postRequest<any, any>(
                "/team/members",
                data,
                `Bearer ${token}`
            );
            console.log(
                "[store/index] Got create team member response: ",
                response
            );
            if (response.status === 200 || response.status === 201) {
                return response;
            } else {
                throw new Error("something went wrong");
            }
        },
        async DOWNLOAD_PCAP(context: ActionContext<StoreI, StoreI>, data) {
            const token = await authenticator.getAccessToken();
            const response = await postRequest<any, any>(
                "/download/pcap",
                data,
                `Bearer ${token}`
            );
            console.log(
                "[store/index] Get the PCAP file response: ",
                response
            );
            if (response.status === 200 || response.status === 201) {
                return response;
            } else {
                throw new Error("something went wrong");
            }
        },
        async PROMETHEUS_QUERY(context: ActionContext<StoreI, StoreI>, data) {
            const token = await authenticator.getAccessToken();
            const response = await postRequest<any, any>(
                "/prometheus/query",
                data,
                `Bearer ${token}`
            );
            console.log(
                "[store/index] Get the prometheus query response: ",
                response
            );
            if (response.status === 200 || response.status === 201) {
                return response;
            } else {
                throw new Error("something went wrong");
            }
        },
        async PROMETHEUS_QUERY_RANGE(context: ActionContext<StoreI, StoreI>, data) {
            const token = await authenticator.getAccessToken();
            const response = await postRequest<any, any>(
                "/prometheus/query_range",
                data,
                `Bearer ${token}`
            );
            console.log(
                "[store/index] Get the prometheus query range response: ",
                response
            );
            if (response.status === 200 || response.status === 201) {
                return response;
            } else {
                throw new Error("something went wrong");
            }
        }
    },
    modules: {}
});
