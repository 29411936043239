
import { Options, Vue } from "vue-class-component";
import Footer from "@/components/partials/general/Footer.vue";
import Navbar from "@/components/partials/general/Navbar.vue";
import Navigation from "@/components/partials/general/Navigation.vue";
import { NavigationInfo, navigations } from "@/util/navigationInfo";
import { NavigationOption } from "@/interfaces/NavigationOption";
import {apiAvailable} from "@/util/http";

@Options({
    components: {
        Footer,
        Navbar,
        Navigation
    }
})
export default class DefaultLayout extends Vue {
    navigationOptions: NavigationOption[] = [];
    selectedNavigationKey = "";

    async created() {
        apiAvailable(); // once called the value is cached, that triggers the cache
        await this.$store.dispatch("GET_ACCOUNT");
        this.selectedNavigationKey = "";
        this.setNavigationOptions();
    }

    get account() {
        return this.$store.getters.account;
    }

    async setNavigationOptions() {
        console.log("acc", this.account);
        this.navigationOptions = navigations.filter( (navigation: NavigationInfo) => {
            return navigation.roles.includes(this.account.roles[0]) || navigation.roles[0] === "ALL";
        }).map(
            (navigation: NavigationInfo) => {
                return {
                    key: navigation.key,
                    displayValue: navigation.name,
                    icon: navigation.icon,
                    roles: navigation.roles
                };
            }
        );
    }
}
